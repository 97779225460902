@font-face {
    font-family: 'Matter';
    src: url('Matter-Bold.woff2') format('woff2'),
        url('Matter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Matter';
    src: url('Matter-BoldItalic.woff2') format('woff2'),
        url('Matter-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Matter';
    src: url('Matter-Heavy.woff2') format('woff2'),
        url('Matter-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Matter';
    src: url('Matter-HeavyItalic.woff2') format('woff2'),
        url('Matter-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Matter';
    src: url('Matter-Light.woff2') format('woff2'),
        url('Matter-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Matter';
    src: url('Matter-LightItalic.woff2') format('woff2'),
        url('Matter-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Matter';
    src: url('Matter-Medium.woff2') format('woff2'),
        url('Matter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Matter';
    src: url('Matter-MediumItalic.woff2') format('woff2'),
        url('Matter-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Matter';
    src: url('Matter-Regular.woff2') format('woff2'),
        url('Matter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Matter';
    src: url('Matter-RegularItalic.woff2') format('woff2'),
        url('Matter-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Matter';
    src: url('Matter-SemiBold.woff2') format('woff2'),
        url('Matter-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Matter';
    src: url('Matter-SemiBoldItalic.woff2') format('woff2'),
        url('Matter-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

