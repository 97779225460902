
.os-scrollbar {
  --os-size: 9px;
  --os-track-border-radius: 3px;
  --os-track-bg-active: none;
  --os-track-bg-hover: none;
  --os-handle-bg:#CCCCDB;
  --os-handle-bg-hover: #BBBBCA;
  --os-handle-bg-active: #BBBBCA;
}

/* Specific class for country select */
.country-select .os-scrollbar {
  padding-bottom: 12px;
}

[data-overlayscrollbars-contents="true"] { 
  display: flex;
  flex-direction: column;
}
