@font-face {
  font-family: "Blacker Display";
  src: url('blacker-pro-display-bold-italic-webfont.woff2') format('woff2'),
    url('blacker-pro-display-bold-italic-webfont.woff') format('woff'),
    url("Blacker-Pro-Display-Bold-Italic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Blacker Display";
  src: url("Blacker-Pro-Display-Bold.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Blacker Display";
  src: url('blacker-pro-display-extrabold-italic-webfont.woff2') format('woff2'),
    url('blacker-pro-display-extrabold-italic-webfont.woff') format('woff'),
    url("Blacker-Pro-Display-ExtraBold-Italic.ttf") format("truetype");
  font-weight: bolder;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Blacker Display";
  src: url('blacker-pro-display-extrabold-webfont.woff2') format('woff2'),
    url('blacker-pro-display-extrabold-webfont.woff') format('woff'),
    url("Blacker-Pro-Display-ExtraBold.ttf") format("truetype");
  font-weight: bolder;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Blacker Display";
  src: url("Blacker-Pro-Display-Heavy-Italic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Blacker Display";
  src: url('blacker-pro-display-heavy-webfont.woff2') format('woff2'),
    url('blacker-pro-display-heavy-webfont.woff') format('woff'),
    url("Blacker-Pro-Display-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Blacker Display";
  src: url('blacker-pro-display-light-italic-webfont.woff2') format('woff2'),
    url('blacker-pro-display-light-italic-webfont.woff') format('woff'),
    url("Blacker-Pro-Display-Light-Italic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Blacker Display";
  src: url('blacker-pro-display-light-webfont.woff2') format('woff2'),
    url('blacker-pro-display-light-webfont.woff') format('woff'),
    url("Blacker-Pro-Display-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Blacker Display";
  src: url('blacker-pro-display-medium-italic-webfont.woff2') format('woff2'),
    url('blacker-pro-display-medium-italic-webfont.woff') format('woff'),
    url("Blacker-Pro-Display-Medium-Italic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Blacker Display";
  src: url('blacker-pro-display-medium-webfont.woff2') format('woff2'),
    url('blacker-pro-display-medium-webfont.woff') format('woff'),
    url("Blacker-Pro-Display-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Blacker Display";
  src: url('blacker-pro-display-regular-webfont.woff2') format('woff2'),
    url('blacker-pro-display-regular-webfont.woff') format('woff'),
    url("Blacker-Pro-Display-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Blacker Display";
  src: url('blacker-pro-display-italic-webfont.woff2') format('woff2'),
    url('blacker-pro-display-italic-webfont.woff') format('woff'),
    url("Blacker-Pro-Display-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
