@tailwind base;
@tailwind components;
@tailwind utilities;

.gradient {
  background: linear-gradient(90deg, #51cb92 1.79%, #00523c 171.15%);
}

html {
  min-height: 100%;
  display: flex;
  /* to avoid unnecessary replaces, such as (6) with ⑥, (5) with ⑤ etc... */
  font-variant-ligatures: no-contextual;
  color: theme('colors.grayscale.750');
}

body {
  flex-grow: 1;
  max-width: 100%;
}

#__next {
  height: 100%;
}

/* Add some styles for focusable elements, to avoid default outline */
button,
a,
textarea {
  @apply focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-200;
}

* {
  /*
  * Disable blue/gray highlighting on the elements when the user taps
  * the screen on mobile.
  */
  @apply antialiased;
  -webkit-tap-highlight-color: transparent;
}

/*
  Remove the border when you click on a slide.
  Currently, it is not possible to control from the props in the <Slide /> component.
*/
.carousel__slide-focus-ring {
  outline: none;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.cart-sidebar {
  transform-origin: right;
}

.cart-sidebar[data-state='open'] {
  animation: slideIn 300ms forwards;
}

.cart-sidebar[data-state='closed'] {
  animation: slideOut 300ms forwards;
}

.react-datepicker__navigation-icon.react-datepicker__navigation-icon--next {
  top: 6px;
}

.react-datepicker__navigation-icon.react-datepicker__navigation-icon--previous {
  top: 6px;
}

.ligatures-none {
  font-variant-ligatures: none;
}

.coupon-mask {
  --coupon-side-radius: 18px;
  --coupon-border-color: #eeeef6; /* border subtle */
  --coupon-border-width: 1px;
  --coupon-background-color: transparent;
  --coupon-bottom-distance: 67px;
  --coupon-side-distance: -4px;

  background-size:
    50% 100%,
    50% 100%;
  background:
    radial-gradient(
        circle at bottom var(--coupon-bottom-distance) left
          var(--coupon-side-distance),
        transparent 0px var(--coupon-side-radius),
        var(--coupon-border-color) var(--coupon-side-radius)
          calc(var(--coupon-side-radius) + var(--coupon-border-width, 0.5px)),
        var(--coupon-background-color)
          calc(var(--coupon-side-radius) + var(--coupon-border-width) + 0.5px)
      )
      no-repeat,
    no-repeat 0 100%,
    100% 100%,
    radial-gradient(
        circle at bottom var(--coupon-bottom-distance) right
          var(--coupon-side-distance),
        transparent 0px var(--coupon-side-radius),
        var(--coupon-border-color) var(--coupon-side-radius)
          calc(var(--coupon-side-radius) + var(--coupon-border-width, 0.5px)),
        var(--coupon-background-color)
          calc(
            var(--coupon-side-radius) + var(--coupon-border-width, 0.5px) +
              0.5px
          )
      )
      no-repeat,
    no-repeat 0 100%,
    100% 100%;

  mask-image: radial-gradient(
      circle at bottom var(--coupon-bottom-distance) left
        var(--coupon-side-distance),
      transparent 0,
      transparent var(--coupon-side-radius),
      #000 calc(var(--coupon-side-radius) + 0.5px)
    ),
    radial-gradient(
      circle at bottom var(--coupon-bottom-distance) right
        var(--coupon-side-distance),
      transparent 0,
      transparent var(--coupon-side-radius),
      #000 calc(var(--coupon-side-radius) + 0.5px)
    );

  mask-size:
    calc(50% + 1px) 100%,
    50% 100%;
  mask-position:
    0 100%,
    100% 100%;
  mask-repeat: no-repeat, no-repeat;
}

.location-banner-gradient {
  background-repeat: no-repeat;
  background-image: radial-gradient(
    circle,
    rgba(13, 139, 102, 1) 0%,
    rgba(41, 53, 86, 0) 60%
  );
}
